/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Frame from '@/layout/Frame.vue'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export const routes = [{
    path: '/',
    name: 'frame',
    redirect: '/home',
    component: Frame,
    children: [{
        path: '/personCenter',
        name: 'personCenter',
        meta: {
          noTop: true,
          title: '犀牛MALL-个人信息'
        },
        redirect: '/myOwnOrder',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/index.vue'),
        children: [{
            path: '/componeyInfo',
            name: 'componeyInfo',
            meta: {
              noTop: true,
              title: '犀牛MALL-公司信息'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */
              '@/views/personCenter/componeyInfo/index.vue')
          },
          {
            path: '/myOwnOrder',
            name: 'myOwnOrder',
            meta: {
              noTop: true,
              title: '犀牛MALL-我的订单'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/myOwnOrder/index.vue')
          },
          {
            path: '/myCoupon',
            name: 'myCoupon',
            meta: {
              noTop: true,
              title: '犀牛MALL-我的优惠券'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/myCoupon/index.vue')
          },
          // {
          //   path: '/myZGB',
          //   name: 'myZGB',
          //   meta: {
          //     noTop: true,
          //     title: '我的中光币'
          //   },
          //   // route level code-splitting
          //   // this generates a separate chunk (about.[hash].js) for this route
          //   // which is lazy-loaded when the route is visited.
          //   component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/myZGB/index.vue')
          // },
          {
            path: '/sendAdress',
            name: 'sendAdress',
            meta: {
              noTop: true,
              title: '犀牛MALL-收货地址'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/sendAdress/index.vue')
          },
          {
            path: '/invoiceInfo',
            name: 'invoiceInfo',
            meta: {
              noTop: true,
              title: '犀牛MALL-发票信息'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/invoiceInfo/index.vue')
          },
          {
            path: '/invoiceOperate',
            name: 'invoiceOperate',
            meta: {
              noTop: true,
              title: '犀牛MALL-开票操作'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */
              '@/views/personCenter/invoiceOperate/index.vue')
          },
          {
            path: '/invoiceApply',
            name: 'invoiceApply',
            meta: {
              noTop: true,
              title: '犀牛MALL-开票申请'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */
              '@/views/personCenter/invoiceApply/index.vue')
          },
          {
            path: '/customerInvoice',
            name: 'customerInvoice',
            meta: {
              noTop: true,
              title: '犀牛MALL-发票信息管理'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */
              '@/views/personCenter/customerInvoice/index.vue')
          },
          {
            path: '/notify',
            name: 'notify',
            meta: {
              noTop: true,
              title: '犀牛MALL-消息通知'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/notify/index.vue')
          },
          {
            path: '/agreement',
            name: 'agreement',
            meta: {
              noTop: true,
              title: '犀牛MALL-电子商务合作协议'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/agreementSettings/index.vue')
          },
          {
            path: '/prove',
            name: 'prove',
            meta: {
              noTop: true,
              title: '犀牛MALL-代付证明'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/payProve/index.vue')
          },
          {
            path: '/settings',
            name: 'settings',
            meta: {
              noTop: true,
              title: '犀牛MALL-系统设置'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/settings/index.vue')
          },
          {
            path: '/cinemaInfo',
            name: 'cinemaInfo',
            meta: {
              noTop: true,
              title: '犀牛MALL-影院信息'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/cinemaInfo/index.vue')
          },
          {
            path: '/equipment',
            name: 'equipment',
            meta: {
              noTop: true,
              title: '犀牛MALL-设备授权'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/equipment/index.vue')
          }
        ]
      },
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '犀牛MALL-首页',
          //isAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/home/Home.vue')
      },
      {
        path: '/search',
        name: 'search',
        meta: {
          title: '犀牛MALL-搜索',
          //isAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/search/index.vue')
      },
      {
        path: '/myOrder',
        name: 'myOrder',
        meta: {
          noTop: true,
          title: '犀牛MALL-订单详情'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/personCenter/myOrder/index.vue')
      },
      {
        path: '/detail',
        name: 'detail',
        meta: {
          noTop: true,
          title: '犀牛MALL-商品详情',
          //isAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/detail/index.vue')
      },
      {
        path: '/orderSuccess',
        name: 'orderSuccess',
        meta: {
          noTop: true,
          title: '犀牛MALL-订单提交成功'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/orderSuccess/index.vue')
      },
      {
        path: '/orderPrint',
        name: 'orderPrint',
        meta: {
          noTop: true,
          title: '犀牛MALL-订单打印'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/orderPrint/index.vue')
      },
      {
        path: '/pay',
        name: 'pay',
        meta: {
          noTop: true,
          title: '犀牛MALL-结算单'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/pay/index.vue')
      },
      {
        path: '/myCar',
        name: 'myCar',
        meta: {
          noTop: true,
          title: '犀牛MALL-我的购物车'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/myCar/index.vue')
      },
      {
        path: '/modal',
        name: 'modal',
        meta: {
          noTop: true,
          title: '犀牛MALL-弹框'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '@/views/modal/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      noTop: true,
      title: '犀牛MALL-登录',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/views/login/caslogin.vue')
  },
  {
    path: '/ext',
    name: 'ext',
    meta: {
      noTop: true,
      title: '犀牛MALL-登录',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/views/login/login.vue')
  },
  {
    path: '/personRegister',
    name: 'personRegister',
    meta: {
      noTop: true,
      title: '犀牛MALL-个人注册',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/views/personRegister/index.vue')
  },
  {
    path: '/companyRegister',
    name: 'companyRegister',
    meta: {
      noTop: true,
      title: '犀牛MALL-企业注册',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/views/companyRegister/index.vue')
  },
  {
    path: '/403',
    name: '403',
    meta: {
      noTop: true,
      title: '犀牛MALL-403',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/components/pagestatus/403.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      noTop: true,
      title: '犀牛MALL-404',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/components/pagestatus/404.vue')
  },
  {
    path: '/500',
    name: '500',
    meta: {
      noTop: true,
      title: '犀牛MALL-500',
      isAuth: true
    },
    component: () => import( /* webpackChunkName: "about" */ '@/components/pagestatus/500.vue')
  },
  {
    path: '/index/home',
    redirect: '/home'
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

export default router
