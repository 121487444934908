import { setStore, getStore } from '@/util/store'

const carList = {
  state: {
    cartList: getStore({ name: 'cartList' }) || {},
    queryReadCount: getStore({ name: 'queryReadCount' }) || 0
  },
  actions: {

  },
  mutations: {
    SET_CART_LIST: (state, action) => {
      state.cartList = action
      setStore({ name: 'cartList', content: state.cartList, type: 'session' })
    },
    SET_QUERY_READ_COUNT: (state, action) => {
      state.queryReadCount = action
      setStore({ name: 'queryReadCount', content: state.queryReadCount, type: 'session' })
    }
  }
}

export default carList
