
<template>
    <div class="footer">
        <div class="content">
            <div class="info">
                <!-- 客服和 置顶 -->
                <top-and-server :stat="stat"></top-and-server>
                <img class="logo" src="@/assets/logo.png" alt="">
                <div class="left info-detail">
                    <div class="title">
                        <span>联系方式</span>
                    </div>
                    <div class="item">
                        <div class="icon">
                            <img src="@/assets/dz.png" alt="">
                        </div>
                        <div class="text">
                            <p class="msg">北京市西城区新德街20号中影器材大楼东配楼3层</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon">
                            <img src="@/assets/kf.png" alt="">
                        </div>
                        <div class="text">
                            <p class="msg">400-870-9700</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon">
                            <img src="@/assets/kf.png" alt="">
                        </div>
                        <div class="text">
                            <p class="msg">010-62366288</p>
                        </div>
                    </div>
                </div>
                <div class="center info-detail">
                    <div class="title">
                        <span>官方平台</span>
                    </div>
                    <div class="item items" @click="zygw">
                        <div class="icon">
                            <img src="@/assets/gsxx1.png" alt="">
                        </div>
                        <div class="text">
                            <span>官网：中影光峰</span>
                        </div>
                    </div>
                  <div class="item items">
                    <div class="icon">
                      <img src="@/assets/wx.png" alt="">
                    </div>
                    <div class="text">
                      <span>微信服务号：中影光峰</span>
                    </div>
                  </div>
<!--                    <div class="item items">
                        <div class="icon">
                            <img src="@/assets/wx.png" alt="">
                        </div>
                        <div class="text">
                            <span>微信订阅号：激光影客</span>
                        </div>
                    </div>-->
                    <div class="item items" @click="txsph">
                        <div class="icon">
                            <img src="@/assets/txsph.png" alt="">
                        </div>
                        <div class="text">
                            <span>腾讯视频号：中影光峰</span>
                        </div>
                    </div>
                    <div class="item items" @click="wbsp">
                        <div class="icon">
                            <img src="@/assets/wb.png" alt="">
                        </div>
                        <div class="text">
                            <span>微博：@中影光峰_CINEAPPO</span>
                        </div>
                    </div>
                    <div class="item items">
                        <div class="icon">
                            <img src="@/assets/dy.png" alt="">
                        </div>
                        <div class="text">
                            <span>抖音：中影光峰</span>
                        </div>
                    </div>
                </div>
                <div class="right-qr">
                    <div class="qr">
                        <img src="@/assets/qr.png" alt="" style="width:87px">
                    </div>
                    <div class="text">
                        <p class="bottomp">扫一扫</p>
                    </div>
                    <div class="text">
                        <p class="bottomp">关注中影光峰公众号</p>
                    </div>
                </div>
                <!--<div class="right-qr">
                    <div class="qr">
                        <img src="@/assets/qr.png" alt="">
                    </div>
                    <div class="text">
                        <p class="bottomp">扫一扫</p>
                    </div>
                    <div class="text">
                        <p class="bottomp">关注中影光峰品牌号</p>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="all-rights">
            <span>Copyright©2021 版权所有：中影光峰激光影院技术（北京）有限公司 <a href="https://beian.miit.gov.cn" class="link" target="_blank">京ICP备15058245号-3</a></span>
        </div>
    </div>
</template>

<script>
import topAndServer from '@/components/topAndServer'
export default {
  components: {
    topAndServer
  },
  data () {
    return {
      stat: false,
      hide: true,
      backPosition: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true) // 监听滚动事件
  },
  computed: {
    showTop () {
      return !this.$route.meta.noTop
    }
  },
  methods: {
    txsph () {
      var mpurl = 'http://v.qq.com/s/videoplus/694329818'
      window.open(mpurl, '_blank')
    },
    zygw () {
      var mpurl = 'http://www.cineappo.com/'
      window.open(mpurl, '_blank')
    },
    wbsp () {
      var mpurl = 'https://weibo.com/6502234675/profile?rightmod=1&wvr=6&mod=personnumber'
      window.open(mpurl, '_blank')
    },
    handleScroll (e) {
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     var scrollTop = this.$parent.$parent.$el.querySelector('.shop-wrap')
      //     scrollTop.scrollTop = scrollTop.scrollHeight
      //   }, 13)
      // })
      const scrollTop = this.$parent.$el.scrollTop
      if (scrollTop > 258) {
        this.stat = true
      } else {
        this.stat = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottomp {
  margin-top: 5px;
}
.msg {
  display: block;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  font-size: 14px;
  line-height: 1.5;
}
.items:hover {
  transition:  all 600ms ease 0s;
  margin-left: 15px;
  color: white;
  cursor: pointer;
}
.footer {
    margin-top: 44px;
    width: 100%;
    position: relative;
    .content {
        width: 100%;
        height: 269px;
        background: #333333;
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        .info {
            width: 1226px;
            position: relative;
            display: flex;
            font-family: "Montserrat", sans-serif;
            justify-content: space-between;
            align-items: center;
            color: #7C7C7C;
            font-size: 14px;
            font-weight: 300;
            .logo{
              width: 135px;
              height: 60px;
            }
            .left {
                width: 250px;
                text-align: left;
            }
            .center {
                width: 400px;
            }
            .info-detail {
              margin-left: 40px;
                .title {
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-bottom: 16px;
                }
                .item {
                    display: flex;
                    align-items: center;
                    .text {
                        padding-left: 7px;
                    }
                }
            }
            .right-qr {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .all-rights {
        height: 40px;
        width: 100%;
        background-color: #2C2C2C;
        text-align: center;
        line-height:40px;
        font-size: 14px;
        color: #7C7C7C;
    }
}
.link {
  color: #7C7C7C;
  text-decoration: none;
}
  .link:hover {
  }
</style>
