import { setStore, getStore } from '@/util/store'

const search = {
  state: {
    searchKeyword: getStore({ name: 'searchKeyword' }) || '',
    searchGoodsId: getStore({ name: 'searchGoodsId' }) || '',
    paramsObjState: getStore({ name: 'paramsObjState' }) || ''
  },
  actions: {

  },
  mutations: {
    SET_SEARCH_KEYWORD: (state, action) => {
      state.searchKeyword = action
      setStore({ name: 'searchKeyword', content: state.searchKeyword, type: 'session' })
    },
    SET_SEARCH_GOODS_ID: (state, action) => {
      state.searchGoodsId = action
      setStore({ name: 'searchGoodsId', content: state.searchGoodsId, type: 'session' })
    },
    SET_PARAMS_OBJ: (state, action) => {
      state.paramsObjState = action
      setStore({ name: 'paramsObjState', content: state.paramsObjState, type: 'session' })
    }
  }
}

export default search
