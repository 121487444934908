import request from '@/router/axios'

// 查询广告
export const findAdvertLinked = (params) => {
  return request({
    url: '/cineplex/homePage/findAdvertLinked',
    method: 'POST',
    params: {
      ...params
    }
  })
}

// 查询广告商品
export const findAdvertNoneLinked = (params) => {
  return request({
    url: '/cineplex/homePage/findAdvertNoneLinked',
    method: 'POST',
    params: {
      ...params
    }
  })
}

// 查询广告商品
export const findAdvertAndGoods = (params) => {
  return request({
    url: '/cineplex/homePage/findAdvertAndGoods',
    method: 'POST',
    params: {
      ...params
    }
  })
}
