import request from '@/router/axios'
import website from '@/config/website'

export const login = (tenantId, loginId, password, type, key, code) => request({
  url: '/cineplex/homePage/wechatLogin',
  method: 'post',
  headers: {
    ticket: 'super'
  },
  params: {
    grantType: (website.captchaMode ? 'captcha' : 'password'),
    tenantId,
    loginId,
    password,
    type,
    key,
    code
  }
})

export const getCustomerInfo = (token) => request({
  url: '/cas/getCustomerInfo',
  method: 'POST',
  data: {
    token: token
  }
})

export const logout = () => request({
  url: '/cineplex/homePage/logout',
  method: 'post'
})

// 页面数据量统计
export const pageLogSend = (params) => request({
  url: '/pagelog/pageInfoSend',
  method: 'post',
  headers: { 'Content-Type': 'application/json' },
  data: params
})
