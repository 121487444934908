import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './permission'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/reset.css'
import '@/styles/quill.core.css'
import '@/styles/quill.snow.css'
import request from '@/router/axios'
import VueRequest from 'vue-axios'
import 'babel-polyfill'

import VuePhotoZoomPro from 'vue-photo-zoom-pro'
import VueLazyload from 'vue-lazyload'

Vue.use(ElementUI)
Vue.use(VueRequest, request)
window.axios = request

Vue.use(VuePhotoZoomPro)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/detail.png'),
  loading: require('./assets/detail.png'),
  attempt: 1
})

Vue.config.productionTip = false

Vue.prototype.$loading = function () {
  return ElementUI.Loading.service({
    lock: true,
    text: '',
    spinner: 'el-icon-loading',
    background: 'rgba(205,205,205,0.4)',
    customClass: 'lod'
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
