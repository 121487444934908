<template>
  <div class="header-content">
      <div class="person-info">
          <person-item>
              <span v-if="userInfo" class="title">{{userInfo.operatorName}}    <span class="no-name" @click="logout">退出</span></span>
              <span v-else class="title"><span @click="$router.push('/login')">你好，请登录    </span><span class="no-name"></span></span>
          </person-item>
          <person-item>
              <span class="title" @click="toPerson">我的订单</span>
          </person-item>
          <person-item>
              <span class="title" @click="toPerson">个人中心</span>
            <div style="position:absolute;right:10px;top:-10px;" v-if="queryReadCount !== 0" class="top">
              <span style="user-select: none;display:inline-block;padding:1px 2px; background:#ef922e;border-radius:3px;font-size:10px;color: white;"><span v-if="queryReadCount <= 99">{{ queryReadCount }}</span><span v-if="queryReadCount > 99">99+</span></span>
            </div>
          </person-item>
        <person-item>
          <span class="title" @click="toEquipment">设备授权</span>
          <!--<div style="position:absolute;right:10px;top:-10px;" v-if="queryReadCount !== 0" class="top">
            <span style="user-select: none;display:inline-block;padding:1px 2px; background:#ef922e;border-radius:3px;font-size:10px;color: white;"><span v-if="queryReadCount <= 99">{{ queryReadCount }}</span><span v-if="queryReadCount > 99">99+</span></span>
          </div>-->
        </person-item>
          <person-item>
              <span class="title" @click="kefu">在线客服</span>
          </person-item>
          <person-item >
              <span class="title" @click="cineappo">激光充值</span>
          </person-item>
        <person-item :showSperate="false">
              <span class="title" @click="zhenzhi">臻陆影购</span>
          </person-item>
      </div>
  </div>
</template>

<script>
import personItem from '@/components/personItem'
import { mapGetters } from 'vuex'
import { queryNotReadCount } from '@/api/personCenter/notify/notify'
// import sha1 from 'js-sha1'
// import { guid } from '@/util/utils'
import { casLogOutUrl, jgczUrl, zzygUrl } from '@/config/env'
export default {
  components: {
    personItem
  },
  computed: {
    ...mapGetters(['userInfo', 'currentCustom', 'queryReadCount'])
  },
  data () {
    return {
      queryCount: 0,
      customer_info: {}
    }
  },
  methods: {
    cineappo () {
      var mpurl = jgczUrl
      // var mpurl = 'https://uc.cineappo.com/cas/login?service=https://recharge.cineappo.com/'// 生产 'https://self.cineappo.com'
      // var mpurl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'// 测试 'https://self.cineappo.com'
      window.open(mpurl, '_blank')
    },
    zhenzhi () {
      var mpurl = zzygUrl
      // var mpurl = 'https://app.filmland.cn/cms/cinema/#/filmList'// 'https://self.cineappo.com/#/login'
      window.open(mpurl, '_blank')
    },
    kefu () {
      // var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&header=header&signature=true&randomn=' + guid()
      // this.$store.commit('SET_IFRAME_SRC', url)
      var mpurl = 'https://work.weixin.qq.com/kfid/kfcb6bbc976623ceb73'
      // var mpurl = 'https://work.weixin.qq.com/kfid/kfc51fdfa81129546b4'
      window.open(mpurl, '_blank')
      // this.customer_info.nonce = this.randomn(6)
      // this.customer_info.timestamp = new Date().getTime()
      // var name = this.userInfo.operatorName
      // if (name) {
      //   this.customer_info.web_token = name
      //   var signStr = `nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
      //   this.customer_info.signature = sha1(signStr).toUpperCase()
      //   url = url + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
      // }
      // this.openOne(url, 'detail')
    },
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    // openOne (url, name) {
    //   var win = window.open('', name)
    //   try {
    //     if (win.location.href === 'about:blank') {
    //       //  窗口不存在
    //       win = window.open(url, name)
    //     } else {
    //       win.close()
    //       //  窗口以已经存在了
    //       //  win.focus()
    //       window.open(url, name)
    //     }
    //   } catch (e) {
    //     //  win.focus()
    //     win.close()
    //     window.open(url, name)
    //   }
    // },
    toPerson () {
      if (this.$route.path !== '/myOwnOrder') {
        this.$store.commit('SET_BREADCRUMB', [])
        this.$store.commit('SET_CURRENT_TAG', '')
      }
      this.$router.push({ path: '/personCenter' })
    },
    toEquipment () {
      this.$router.push({ path: '/equipment' })
    },
    logout () {
      this.$store.dispatch('LogOut').then(() => {
        window.location.href = casLogOutUrl
      })
    },
    onLoad () {
      queryNotReadCount(this.currentCustom.customerId).then(res => {
        this.queryCount = res.data.data
        this.$store.commit('SET_QUERY_READ_COUNT', this.queryCount)
      })
    }
  },
  mounted () {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
.header-content {
    width: 100%;
    height: 40px;
    background-color: #333333;
    display: flex;
    justify-content: center;
    align-items: stretch;
    .person-info {
        width: 1226px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .title {
          color: #A2A2A2;
        }
        .title:hover {
          cursor: pointer;
          color: #ef922e;
        }
        .no-name {
            color:#EC5724;
        }
    }
}
</style>
