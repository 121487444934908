import { setStore, getStore } from '@/util/store'

const detail = {
  state: {
    detailId: getStore({ name: 'detailId' }) || ''
  },
  actions: {

  },
  mutations: {
    SET_DETAIL_ID: (state, action) => {
      state.detailId = action
      setStore({ name: 'detailId', content: state.detailId, type: 'session' })
    }
  }
}

export default detail
