/**
 * 全站http配置
 */
import axios from 'axios'
import store from '@/store'
import router from '@/router/index'
import { baseUrl } from '@/config/env'
import { Base64 } from 'js-base64'
import website from '@/config/website'
import qs from 'qs'
import { generateUUID } from '@/util/utils'

axios.defaults.timeout = 500000

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}

// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true

const single = axios.create({
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
})

// HTTP request拦截
single.interceptors.request.use(config => {
  // NProgress.start() // start progress bar
  config.headers.Authorization = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`
  if (store.getters.token) {
    config.headers['cineplex-web-token'] = store.getters.token
  }
  if (!config.headers.ticket) {
    config.headers.ticket = store.getters.userInfo.ticket
  }
  var uuid = store.getters.uniqueId
  if (!uuid) {
    uuid = generateUUID()
    store.commit('SET_UNIQUE_ID', uuid)
  }
  config.headers.uniqueId = uuid
  // headers中配置serialize为true开启序列化
  if (config.headers['Content-Type'] === 'multipart/form-data') {
    window.console.debug('')
  } else if (config.headers['Content-Type'] === 'application/json') {
    config.data = JSON.stringify(config.data)
  } else {
    config.data = qs.stringify(config.data)
  }
  return config
}, error => {
  return Promise.reject(error)
})

// HTTP response拦截
single.interceptors.response.use(res => {
  // NProgress.done()
  const status = res.data.code + ''
  const contentTypeName = res.headers['content-type'] + ''
  const message = res.data.msg || '未知错误'
  // 如果在白名单里则自行catch逻辑处理
  if (status === '401') store.dispatch('LogOut').then(() => router.push({ path: '/login' }))
  if (status === '403') router.push({ path: '/403' })
  // 如果请求为非200否者默认统一处理
  if (status !== '0' && status !== 'server' && status !== '200') {
    if (contentTypeName.indexOf('application/vnd.ms-excel') !== -1) {
      return res
    } else {
      return Promise.reject(new Error(message))
    }
  }
  return res
}, error => {
  // NProgress.done()
  return Promise.reject(new Error(error))
})

export default single
