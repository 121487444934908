<template>
  <div>
    <div class="fixedBox" v-show="upShow">
      <span class="fixedBoxSpan" style="cursor: pointer" @click="udClose"/>
      <iframe width="100%" height="100%" :src="iframeSrcTmp" />
    </div>
    <div class="fix-div" v-if="stat">
      <div class="fix-top img-fix-home" ><!-- 右边 -->
        <a><img src="@/assets/fixTop.png" @click="backToTop" alt="置顶"></a>
        置顶
      </div>
      <div class="fix-kf img-fix-home" @click="goCar" v-if="userInfo.operatorName != null">
        <a><img src="@/assets/home5.png" alt="购物车" ></a>
        购物车
      </div>
      <div class="fix-kf img-fix-home search-right-bottom"><!-- @click="udNativeClick('')" -->
        <a><img src="@/assets/fixKf.png" alt="客服"></a>
        客服
        <div class="position_absolute">
          <div style="margin-top: 10px;">
            <div class="search-right-top">
              <img src="@/assets/img/kefu.png" style="height: 175px;width: 150px;" alt="logo">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fix-div two" v-if="userInfo.operatorName != null && stat" >
      <div class="fix-top img-fix-home" @click="goOwnOrder"><!-- 左边 -->
        <a><img src="@/assets/home2.png" alt="订单"></a>
          订单
      </div>
      <div class="fix-kf img-fix-home" @click="goInvoiceApply">
        <a><img src="@/assets/home1.png" alt="发票"></a>
         发票
      </div>
      <div class="fix-kf img-fix-home" @click="goCoupon">
        <a><img src="@/assets/home3.png" alt="优惠券"></a>
         优惠券
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import sha1 from 'js-sha1'
import { getStore } from '@/util/store'

(function (a, h, c, b, f, g) {
  a.UdeskApiObject = f
  a[f] = a[f] || function () {
    (a[f].d = a[f].d || []).push(arguments)
  }
  g = h.createElement(c)
  g.async = 1
  g.src = b
  c = h.getElementsByTagName(c)[0]
  c.parentNode.insertBefore(g, c)
  // eslint-disable-next-line func-call-spacing
})
// eslint-disable-next-line no-unexpected-multiline
(window, document, 'script', 'https://cineappo.s4.udesk.cn/im_client/js/udeskApi.js', 'ud')
var userInfoTemp = getStore({ name: 'userInfo' })
// if (userInfoTemp && userInfoTemp.operatorName) {
var paramsTemp = {
  code: '8hg3ajb',
  link: 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626'
}
paramsTemp.customer = {}
paramsTemp.customer.nonce = parseInt((Math.random() + 1) * Math.pow(10, 6 - 1))
paramsTemp.customer.timestamp = new Date().getTime()
try {
  var name = userInfoTemp.operatorName
  if (name) {
    paramsTemp.customer.web_token = name
    var signStr = `nonce=${paramsTemp.customer.nonce}&timestamp=${paramsTemp.customer.timestamp}&web_token=${paramsTemp.customer.web_token}&e9402bfd15f27fba780d906ce451e3c4`
    paramsTemp.customer.signature = sha1(signStr).toUpperCase()
  }
} catch (e) {
}
// eslint-disable-next-line no-undef
window.ud(paramsTemp)
// eslint-disable-next-line no-undef
// }
export default {
  computed: {
    ...mapGetters(['breadcrumbList', 'userInfo', 'customInfos', 'currentCustom', 'iframeSrc', 'udShow'])
  },
  props: {
    stat: Boolean
  },
  data () {
    return {
      customer_info: {},
      upShow: false,
      iframeSrcTmp: 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&signature=true'
    }
  },
  created () {

  },
  watch: {
    iframeSrc: function (val) {
      if (val.indexOf('&header=header') !== -1) {
        this.udNativeClick()
      } else if (this.$route.path === '/invoiceApply' || this.$route.path === '/detail' || this.$route.path === '/myOwnOrder' || this.$route.path === '/orderSuccess' || this.$route.path === '/agreement') {
        // if (val.indexOf('signature') === -1) {
        //   this.customer_info.nonce = this.randomn(6)
        //   this.customer_info.timestamp = new Date().getTime()
        //   var name = this.userInfo.operatorName
        //   if (name) {
        //     this.customer_info.web_token = name
        //     var signStr = `nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
        //     this.customer_info.signature = sha1(signStr).toUpperCase()
        //     val = val + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
        //   }
        // }
        // this.iframeSrcTmp = val
        // this.upShow = true
        this.udNativeClick()
      }
    },
    udShow: function (val) {
      if (this.$route.path === '/invoiceApply' || this.$route.path === '/detail' || this.$route.path === '/myOwnOrder') {
        this.upShow = val
      }
    }
  },
  methods: {
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    getUrlParams (url, variable) {
      var query = url.substring(url.indexOf('?') + 1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return ''
    },
    udClick () {
      var url = ''
      if (!this.iframeSrc) {
        if (!this.iframeSrcTmp) {
          url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626'
        } else {
          url = this.iframeSrcTmp
        }
      } else {
        url = this.iframeSrc
      }
      if (url.indexOf('signature') === -1) {
        this.customer_info.nonce = this.randomn(6)
        this.customer_info.timestamp = new Date().getTime()
        var name = this.userInfo.operatorName
        if (name) {
          this.customer_info.web_token = name
          var signStr = `nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
          this.customer_info.signature = sha1(signStr).toUpperCase()
          url = url + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
        }
      }
      this.iframeSrcTmp = url
      this.upShow = true
      this.$store.commit('SET_UD_SHOW', true)
      // if (this.$route.path === '/detail' || this.$route.path === '/myOwnOrder') {
      //   if (this.iframeSrc) {
      //     this.iframeSrcTmp = this.iframeSrc
      //     this.upShow = true
      //   }
      // } else {
      //
      // }
    },
    udClose () {
      this.$store.commit('SET_UD_SHOW', false)
      this.upShow = false
    },
    udNativeClick (urlTemp = '') {
      var url = urlTemp
      if (!url) {
        if (!this.iframeSrc) {
          if (!this.iframeSrcTmp) {
            url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&signature=true'
          } else {
            url = this.iframeSrcTmp
          }
        } else {
          url = this.iframeSrc
        }
      }
      var params = {
        code: '8hg3ajb',
        link: 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626'
      }
      if (url.indexOf('signature') !== -1) {
        params.customer = {}
        params.customer.nonce = this.randomn(6)
        params.customer.timestamp = new Date().getTime()
        var name = this.userInfo.operatorName
        if (name) {
          params.customer.web_token = name
          var signStr = `nonce=${params.customer.nonce}&timestamp=${params.customer.timestamp}&web_token=${params.customer.web_token}&e9402bfd15f27fba780d906ce451e3c4`
          params.customer.signature = sha1(signStr).toUpperCase()
        }
      }
      if (url.indexOf('product_') !== -1) {
        params.product = {}
        params.product.title = decodeURIComponent(this.getUrlParams(url, 'product_title'))
        params.product.url = decodeURIComponent(this.getUrlParams(url, 'product_url'))
        params.product.image = decodeURIComponent(this.getUrlParams(url, 'product_image'))
        params.product.send = this.getUrlParams(url, 'product_send')
        if (url.indexOf('product_价格') !== -1) {
          params.product['价格'] = decodeURIComponent(this.getUrlParams(url, 'product_价格'))
          params.product['促销价'] = decodeURIComponent(this.getUrlParams(url, 'product_促销价'))
        }
        if (url.indexOf('product_订单编号') !== -1) {
          params.product['订单编号'] = decodeURIComponent(this.getUrlParams(url, 'product_订单编号'))
        }
      }
      window.ud(params)
      window.document.querySelector('#udesk_btn a').click()
    },
    backToTop () {
      this.$nextTick(() => {
        let top = this.$parent.$parent.$el.scrollTop
        const timeTop = setInterval(() => {
          this.$parent.$parent.$el.scrollTop = top -= 50
          if (top <= 0) {
            clearInterval(timeTop)
          }
        }, 10)
      })
    },
    goOwnOrder () {
      this.$router.push({ // 调转到我的订单
        path: '/myOwnOrder'
      })
    },
    goInvoiceApply () {
      this.$router.push({ // 调转到我的发票
        path: '/invoiceApply'
      })
    },
    goCoupon () {
      this.$router.push({ // 调转到我的优惠券
        path: '/myCoupon'
      })
    },
    goCar () {
      this.$router.push({ // 调转到我的购物车
        path: '/myCar'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .fixedBox{
    z-index: 1000;
    width: 24%;
    height: 76%;
    position: fixed;
    right: 4%;
    bottom: 0px;
    background: #eee;
    border: 0px solid #ffffff;
    .fixedBoxSpan{
      position:absolute;
      right:40px;
      top:30px;
      background:#FFF;
      border:1px solid #FFF;
      width:16px;
      height:0px;
    }
  }
.fix-div {
    position:fixed;
    bottom: 12%;
    right: 50%;
    margin-right: -680px;
    .img-fix-home {
        width: 60px;
        height: 80px;
        background:#808080;
        color:#FFF;
        img {
            display: block;
            width: 100%;
            height: 60px;
        }
    }
    .fix-kf {
        margin-top: 10px;
    }
}
.two{
  bottom: 12%;
  width: 60px;
  left: 50%;
  margin-left: -695px;
}
.search-right-bottom {
  color: #666666;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
  &:hover {
    .position_absolute {
      display: block;
    }
  }
  .position_absolute{
    display: none;
    background: #fff;
    z-index:999;
    position: absolute;
    top: 85px;
    // padding: 0 10px;
    border-radius: 6px;
    left: -156px;
    /*right: -62%;*/
  }
}
</style>
