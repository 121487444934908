import request from '@/router/axios'

/**
 * 消息查看
 * @returns {AxiosPromise}
 */
export const updateMsgInfo = (pam, userId) => {
  return request({
    url: '/cineplex/homePageOther/updateMsgInfo',
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    params: {
      msgId: pam.id,
      userId: userId
    }
  })
}

/**
 * 消息分页列表
 * @returns {AxiosPromise}
 */
export const queryMsgList = (params) => {
  return request({
    url: '/cineplex/homePageOther/queryMsgList',
    method: 'get',
    // headers: { 'Content-Type': 'application/json' },
    params: {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      userId: params.userId,
      readStatus: params.readStatus,
      dateNumber: params.dateNumber
    }
  })
}

/**
 * 未读数量查询
 * @returns {AxiosPromise}
 */
export const queryNotReadCount = (userId) => {
  return request({
    url: '/cineplex/homePageOther/queryNotReadCount',
    method: 'get',
    // headers: { 'Content-Type': 'application/json' },
    params: {
      userId: userId
    }
  })
}
