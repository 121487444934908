import { setStore, getStore } from '@/util/store'

const breadcrumb = {
  state: {
    breadcrumbList: getStore({ name: 'breadcrumbList' }) || [],
    currentTag: getStore({ name: 'currentTag' }) || '1',
    currentStat: getStore({ name: 'currentStat' }) || '0',
    lastInvoiceInfoStat: getStore({ name: 'lastInvoiceInfoStat' }) || '01'
  },
  actions: {

  },
  mutations: {
    SET_BREADCRUMB: (state, action) => {
      state.breadcrumbList = action
      setStore({ name: 'breadcrumbList', content: state.breadcrumbList, type: 'session' })
    },
    SET_CURRENT_TAG: (state, action) => {
      state.currentTag = action
      setStore({ name: 'currentTag', content: state.currentTag, type: 'session' })
    },
    SET_CURRENT_STAT: (state, action) => {
      state.currentStat = action
      setStore({ name: 'currentStat', content: state.currentStat, type: 'session' })
    },
    SET_LAST_INVOICE_INFO_STAT: (state, action) => {
      state.lastInvoiceInfoStat = action
      setStore({ name: 'lastInvoiceInfoStat', content: state.lastInvoiceInfoStat, type: 'session' })
    }
  }
}

export default breadcrumb
