<template>
  <div class="shop-wrap" ref="frame">
    <div v-if="advertObj && advertObj.img" class="recommend-img">
      <div class="dianWo" @click="openUrl(advertObj.url)">
        <img :src="advertObj.img" @error="imgError(errorUrl='')">
      </div>
    </div>
    <div class="header-wrap">
      <Header />
    </div>
    <div class="main-wrap">
      <router-view />
    </div>
    <div class="footer-wrap">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/layout/Header'
import Footer from '@/layout/Footer'
// import { showHomeTopAdvert } from '@/api/personCenter/addressInfo/advertL'
import { findAdvertLinked } from '@/api/home'
import { hits } from '@/api/hits'
export default {
  data () {
    return {
      advertObj: {}
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    openUrl (url) {
      hits({
        type: '02',
        clickPostion: '07',
        busId: '07',
        busTitle: '顶图',
        sourceSection: '01'
      })
      window.open(url, '_blank')
    },
    // 图片未加载出来时候的默认图片
    imgError (errorUrl) {
      errorUrl = require('../assets/recommend.png')
    },
    onLoad () {
      // showHomeTopAdvert().then(res => {
      //   this.advertList = res.data.data
      //   this.recommendImg = this.advertList[0].homePageImg
      //   this.show = true
      // })
      findAdvertLinked({ type: '01', position: '07' }).then(res => {
        try {
          this.advertObj = res.data.data.ADVERT_POSTION_NAME[0]
        } catch (e) {
        }
      })
    }
  },
  created () {
    this.onLoad()
  },
  mounted () {
    this.$store.state.frame_ = this.$refs.frame
  }
}
</script>

<style lang="scss" scoped>
.dianWo:hover {
  cursor: pointer;
}
.shop-wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
  // background-color: #f4f4f4;
  position: relative;
  .recommend-img {
    width: 100%;
    min-width: 1226px; // 1903x177
    img {
      width: 100%;
      display: block;
      height: auto;
      //margin-right: auto;
      //margin-left: auto;
    }
    background-size: cover;
  }
  .header-wrap {
    min-width: 1226px;
  }
  .footer-wrap {
    min-width: 1226px;
  }
}
</style>
