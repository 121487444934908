import router from './router'
import store from './store'
import { pageLogSend } from './api/core'
// import { getToken } from '@/util/auth'

router.beforeEach((to, from, next) => {
  const meta = to.meta || {}
  const forwardUrl = window.location.href
  document.title = to.meta.title
  // from.fullPath to.fullPath
  // window.console.log('from=>', from)
  // window.console.log('to=>', to)
  const sendParams = {
    pageTitle: to.meta.title,
    pageUrl: to.fullPath,
    pageQuery: window.JSON.stringify(to.query)
  }
  if (store.getters.token) {
    if (to.path === '/login') {
      // 如果登录成功访问登录页跳转到主页
      next({ path: '/' })
      pageLogSend(sendParams)
    } else {
      //  如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
      if (store.getters.token.length === 0) {
        if (meta.isAuth === false) {
          next()
          pageLogSend(sendParams)
        } else {
          next({ path: '/login', query: { forwardUrl: forwardUrl } })
        }
      } else {
        next()
        pageLogSend(sendParams)
      }
    }
  } else {
    if (meta.isAuth) {
      next()
      pageLogSend(sendParams)
    } else {
      next({ path: '/login', query: { forwardUrl: forwardUrl } })
    }
  }
})

router.afterEach((to, from, next) => {
  if (store.state.frame_) {
    try {
      store.state.frame_.scrollTop = 0
    } catch (e) {

    }
  }
})
