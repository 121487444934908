import { setStore, getStore } from '@/util/store'
import { login, logout } from '@/api/core'

const user = {
  state: {
    userInfo: getStore({ name: 'userInfo' }) || '',
    token: getStore({ name: 'token' }) || '',
    customInfos: getStore({ name: 'customInfos' }) || [],
    currentCustom: getStore({ name: 'currentCustom' }) || '',
    iframeSrc: getStore({ name: 'iframeSrc' }) || '',
    udShow: getStore({ name: 'udShow' }) || '',
    uniqueId: getStore({ name: 'uniqueId' }) || ''
  },
  actions: {
    Login ({ commit, state }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo.tenantId, userInfo.username, userInfo.password, userInfo.type, userInfo.key, userInfo.code).then(res => {
          const data = res.data.data
          commit('SET_TOKEN', data.accessToken)
          commit('SET_USER_INFO', data)
          commit('SET_CUSTOM_INFO_S', data)
          if (state.customInfos.length > 0) {
            commit('SET_CURRENT_CUSTOM', state.customInfos[0])
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USER_INFO', '')
          commit('SET_CUSTOM_INFO_S', '')
          commit('SET_CURRENT_CUSTOM', '')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    SET_TOKEN: (state, action) => {
      state.token = action
      setStore({ name: 'token', content: state.token })
    },
    SET_USER_INFO: (state, action) => {
      state.userInfo = action
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_CUSTOM_INFO_S: (state, action) => {
      if (action) {
        const temp1 = action.customerId.split(',')
        const temp2 = action.customerName.split(',')
        const customInfo = []
        //  customInfo.push({ customerId: '', customerName: '所有管理企业' })
        for (let i = 0; i < temp1.length; i++) {
          customInfo.push({ customerId: temp1[i], customerName: temp2[i] })
        }
        state.customInfos = customInfo
      } else {
        state.customInfos = action
      }
      setStore({ name: 'customInfos', content: state.customInfos })
    },
    SET_CURRENT_CUSTOM: (state, action) => {
      state.currentCustom = action
      setStore({ name: 'currentCustom', content: state.currentCustom })
    },
    SET_IFRAME_SRC: (state, action) => {
      state.iframeSrc = action
      setStore({ name: 'iframeSrc', content: state.iframeSrc, type: 'session' })
    },
    SET_UD_SHOW: (state, action) => {
      state.udShow = action
      setStore({ name: 'udShow', content: state.udShow, type: 'session' })
    },
    SET_UNIQUE_ID: (state, action) => {
      state.uniqueId = action
      setStore({ name: 'uniqueId', content: state.uniqueId })
    }
  }
}

export default user
