/**
 * 全局配置文件
 */
export default {
  clientId: 'L', // 客户端id
  clientSecret: '123456', // 客户端密钥
  captchaMode: false, // 是否开启验证码模式
  logo: 'cineplex',
  key: 'cineplex', // 配置主键,目前用于存储
  tokenTime: 6000,
  // http的status默认放行不才用统一处理的,
  statusWhiteList: [],
  // 授权地址
  authUrl: ''
}
