import { setStore, getStore } from '@/util/store'

const goodsCarNum = {
  state: {
    goodsCarNum: getStore({ name: 'goodsCarNum' }) || {}
  },
  actions: {

  },
  mutations: {
    SET_CART_NUM: (state, action) => {
      state.goodsCarNum = action
      setStore({ name: 'goodsCarNum', content: state.goodsCarNum, type: 'session' })
    }
  }
}

export default goodsCarNum
