import request from '@/router/axios'
import store from '@/store'

// 商品、广告点击量
export const hits = (params) => {
  return request({
    url: '/cineplex/homePage/censusClick',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...params,
      sourceType: '01',
      customerId: store.getters.currentCustom.customerId,
      customerName: store.getters.currentCustom.customerName
    }
  })
}
