import Vue from 'vue'
import Vuex from 'vuex'
import breadcrumb from './module/breadcrumb'
import search from './module/search'
import detail from './module/detail'
import carList from './module/carList'
import goodsCarNum from './module/goodsCarList'
import user from './module/user'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    frame_: ''
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    breadcrumb,
    search,
    detail,
    carList,
    goodsCarNum,
    user
  },
  getters
})
