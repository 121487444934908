
const getters = {
  breadcrumbList: state => state.breadcrumb.breadcrumbList,
  currentTag: state => state.breadcrumb.currentTag,
  currentStat: state => state.breadcrumb.currentStat,
  lastInvoiceInfoStat: state => state.breadcrumb.lastInvoiceInfoStat,
  searchKeyword: state => state.search.searchKeyword,
  searchGoodsId: state => state.search.searchGoodsId,
  detailId: state => state.detail.detailId,
  cartList: state => state.carList.cartList,
  goodsCarNum: state => state.goodsCarNum.goodsCarNum,
  paramsObjState: state => state.search.paramsObjState,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  frame_: state => state.user.frame_,
  customInfos: state => state.user.customInfos,
  currentCustom: state => state.user.currentCustom,
  iframeSrc: state => state.user.iframeSrc,
  udShow: state => state.user.udShow,
  uniqueId: state => state.user.uniqueId,
  queryReadCount: state => state.carList.queryReadCount
}

export default getters
